import React, {useRef, useState} from "react"
import { Typography, Box, TextField } from "@mui/material";
// import EastIcon from '@mui/icons-material/East';
import { functions } from '../firebase/index';
import { httpsCallable } from "firebase/functions";
import ColorButton from "./coloredButton";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import { useTheme } from '@mui/material/styles';


function NewsLetter() {

    const emailRef = useRef()
    // const theme = useTheme()

    // const [err, setErr] = useState('')
    const [alertState, setAlertState] = useState('none')
    const [myAlert, setMyAlert] = useState(<></>)

    const newsLetter = httpsCallable(functions, "newsletter");

    const SuccessAlert = () =>{

    return(

            <Alert 
                icon={false}
                severity="success" 
                // color={'#f3ede0'}
                sx={{width: '95%'}}>
    
                <AlertTitle 
                    sx={{
                        fontFamily: 'Neuton',
                        fontWeight: 500,
                        fontSize: {xs: 16, md: 20}
                    }}>
                    Thank you!
                </AlertTitle>
    
                <Typography
                    textAlign={'justify'}
                    sx={{
                        lineHeight: 1.5,
                        fontFamily: 'Neuton',
                        fontWeight: 300,
                        fontSize: {xs: 16, md: 18}
                        }}>
                    Your email has been successfully added to our newsletter list. Stay tuned!
                </Typography>
    
                
            </Alert>
        )
    }

    const ErrorAlert = () =>{

        return(
    
                <Alert 
                    icon={false}
                    severity="warning" 
                    // color={'#f3ede0'}
                    sx={{width: '95%'}}>
        
                    <AlertTitle 
                        sx={{
                            fontFamily: 'Neuton',
                            fontWeight: 500,
                            fontSize: {xs: 16, md: 20}
                        }}>
                        Oh no!
                    </AlertTitle>
        
                    <Typography
                        textAlign={'justify'}
                        sx={{
                            lineHeight: 1.5,
                            fontFamily: 'Neuton',
                            fontWeight: 300,
                            fontSize: {xs: 16, md: 18}
                            }}>
                        We had difficulties adding your email to our list. Give it another shot?
                    </Typography>
        
                    
                </Alert>
            )
        }

    async function handleSubmit() {

        try {
            newsLetter({email: emailRef.current.value})
            .then( res => {
                emailRef.current.value = ''
                setAlertState('flex')
                setMyAlert(<SuccessAlert />)
            })
            .catch( error => {
                emailRef.current.value = ''
                setAlertState('flex')
                setMyAlert(<ErrorAlert />)
            })
        } catch {

        }

    }

    return(
        <Box sx={{
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            width: {xs: '95%', md: '80%'}}}>

            {/* Header */}
            <Box sx={{
                display: 'flex', 
                alignItems: 'center', 
                // background: 'yellow',
                width: '100%', 
                height: '100%', 
                marginBottom: '1.5rem',}}>
                <Typography 
                    textAlign={'left'}
                    sx={{
                        lineHeight: 2,
                        fontFamily: 'Neuton',
                        fontSize: {xs: 18, md: 20}
                        }}>
                    Your journey with Pantry starts here
                </Typography>
            </Box>

            <Box sx={{
                display: 'flex', 
                alignItems: 'center', 
                // background: 'yellow',
                width: '100%', 
                height: '100%', 
                marginBottom: '1.5rem',
                }}>
                {/* 3 */}
                <Typography
                    textAlign={'justify'}
                    sx={{
                        lineHeight: 1.5,
                        fontFamily: 'Neuton',
                        // marginBottom: '16px',
                        fontWeight: 300,
                        fontSize: {xs: 16, md: 18}
                        }}>
                    For exciting news, exclusive events, & delicious products subscribe to our newsletter.
                </Typography>
            </Box>

            {/* Input and submit */}
            <Box sx={{
                display: 'flex', 
                flexDirection: {xs: 'column', sm: 'row'},
                alignItems: "end", 
                // background: 'yellow',
                width: '100%', 
                height: '100%', 
                marginBottom: '1.5em',}}>

                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    width: {xs: '100%', sm: '80%'}, 
                    mb: {xs: '1.5rem', sm: 0}, 
                    height: '100%', }}>
                    <TextField 
                        variant='standard'
                        // fullWidth 
                        required 
                        label="Email"
                        type="email"
                        color="secondary"
                        inputRef={emailRef} 
                        InputLabelProps={{
                            sx: {
                            fontFamily: 'Neuton',
                            fontSize: 16,
                            }
                        }} 
                        sx={{width: {xs: '100%', sm: '95%'}}}
                        />
                </Box>
                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    width: {xs: '100%', sm: '20%'}, 
                    height: '100%', }}>

                    <ColorButton
                        variant='contained' 
                        disableElevation
                        sx={{
                            width: {xs: '100%', sm: '95%'},
                    
                        }}
                        onClick={handleSubmit}
                        >
                        SUBSCRIBE
                    </ColorButton>

                </Box>
            </Box>

            {/* Alert */}
            <Box sx={{
                display: {alertState}, 
                // display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                width: '100%', 
                height: '100%', 
                marginBottom: '1.5rem',
                }}>

                {/* <SuccessAlert /> */}
                {myAlert}

            </Box>

        </Box>
    )
}

export default NewsLetter