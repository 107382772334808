import React from "react"
import { Typography, Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';


function SpecialtyTitleBlock({Name, Size}) {

    return(
        <>
            <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                width: '100%'
                }}>
                <Typography 
                    textAlign={'left'} 
                    gutterBottom
                    sx={{
                        fontSize: {xs: 28, md: 32}, 
                        lineHeight: 2,
                        fontFamily: 'Neuton', 
                        fontWeight: 400,
                        color: grey[800],
                        }}>
                    {Name}
                </Typography>
            </Box>

            <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                // mt: '1rem',
                }}>
                <Typography 
                    textAlign={'left'} 
                    gutterBottom
                    sx={{
                        fontSize: {xs: 22, md: 26}, 
                        lineHeight: 1,
                        fontFamily: 'Neuton', 
                        fontWeight: 300,
                        }}>
                    {Size}
                </Typography>
            </Box>
        </>
    )
}

export default SpecialtyTitleBlock