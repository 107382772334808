import React , {
    useState,
    useContext,
    useEffect
} from "react";
import Layout from "../components/layout";
// import { ProductsContext } from "../context/products-context";
import { 
    Typography, 
    Box, 
    Container, 
    Grid2,
    Paper
} from "@mui/material";
// import { grey } from "@mui/material/colors";
// import ProductGallery from "../components/productGallery";
// import { useNavigate} from "react-router-dom";
// import Fake from "../components/fake";
import { styled, useTheme } from '@mui/material/styles';
// import CircularProgress from '@mui/material/CircularProgress';
import { Helmet } from "react-helmet";
import { grey } from "@mui/material/colors";
// import { Container } from "@mui/material";
// import IconBreadcrumbs from "../components/iconBreadCrumbs";
import PantryProgress from "../components/pantryCircularProgress";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import img1 from '../assets/image6.jpg';
import img2 from '../assets/uni.jpeg';
import img3 from '../assets/image22.jpg';
import img4 from '../assets/lemonTartlet.jpg';
import Emailform2 from "../components/emailForm2";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));


export default function PrivateDining() {

    const [loading, setLoading] = useState(true)
    const [value, setValue] = useState(null);
    const [cutoff, setCutoff] = useState(1);
    const theme = useTheme();

        var tot = dayjs()

    useEffect( () => {

        setLoading(false)

        var tot = dayjs()
        setCutoff(tot.$H > 11 ? 2 : 1)

        return( () => {})

    }, [])


    return(
        <div>
            <Helmet>
                <title>Private Dining</title>
                {/* <meta name="description" content={'The pantry bakery and catering provides unique culinary experience centered around locally sourced, fresh, and mostly organic ingredients'} />
                <meta name="keywords" content="novello, oilive oil, novello olive oil, italian olive oil, pantry, pantry bakery, pantry catering, event catering, plant-based catering, organic catering, authentic korean food catering, low fat lunch, low sodium lunch box, corporate catering, corporate lunch box, salt bread, korean salt bread, grazing box, Orange counry catering, Los Angeles catering, team building lunch catering, executive breakfast, corporate event catering, fresh, special event lunch box" />

                <meta property="og:title" content={'Shop the Pantry'} />
                <meta property="og:description" content={'The pantry bakery and catering provides unique culinary experience centered around locally sourced, fresh, and mostly organic ingredients'} />
                <meta property="og:image" content={URL of the image you want to use} />
                <meta property="og:url" content={'https://thepantry.catering/shop'} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={'Shop the Pantryp'}  />
                <meta name="twitter:description" content={'The pantry bakery and catering provides unique culinary experience centered around locally sourced, fresh, and mostly organic ingredients'} /> */}
            </Helmet>
            <Layout>
                <Container  maxWidth='md'>

                    {
                        loading ? 

                        <PantryProgress />

                        :

                        <Box sx={{
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            minHeight: '55vh',
                            // height: '100%',
                            pt: '8rem',
                            pb: '3rem',
                            // background: 'teal'
                            }}>

                            {/* Header */}
                            <Box sx={{
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                // background: 'red'
                                }}>
                                <Typography 
                                    textAlign={'center'} 
                                    sx={{
                                        fontFamily: 'Neuton',
                                        fontSize: {xs: 36, md: 48},
                                        lineHeight: 2,  
                                        fontWeight: 400,
                                        color: grey[800], 
                                        // pt: '4%',
                                        pb: {md: '1rem'},
                                        width: '100%', 
                                        height: '100%'}}>
                                    Private Dining
                                    {/* Welcome to The Pantry private dining, Where Every Meal is an Experience */}

                                </Typography>
                            </Box>

                            {/* Sub Header */}
                            <Box sx={{
                                display: {xs: 'none', md: 'flex'}, 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                pb: '1rem',
                                }}>

                                <Typography 
                                    textAlign={'center'} 
                                    sx={{
                                        fontFamily: 'Neuton',
                                        fontSize: {xs: 20, md: 24},
                                        // mb: '2rem',
                                        fontWeight: 300,
                                        width: '100%', 
                                        height: '100%'}}>

                                        Welcome to The Pantry private dining, Where Every Meal is an Experience

                                </Typography>
                            </Box>

                            <Box sx={{
                                display: {xs: 'flex', md: 'none'}, 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                pb: '1rem',
                                }}>

                                <Typography 
                                    textAlign={'center'} 
                                    sx={{
                                        fontFamily: 'Neuton',
                                        fontSize: {xs: 20, md: 24},
                                        // mb: '2rem',
                                        fontWeight: 300,
                                        width: '100%', 
                                        height: '100%'}}>

                                        Welcome to The Pantry private dining, <br/>
                                        Where Every Meal is an Experience

                                </Typography>
                            </Box>

                            {/* Description */}
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center', 
                                justifyContent: 'center',
                                width: '95%',
                                pb: '1rem',
                                pt: '1rem',
                                // background: 'red'
                                }}>

                                <Typography
                                    textAlign={'justify'} 
                                    sx={{
                                        marginBottom: '14px',
                                        fontFamily: 'Neuton',
                                        fontWeight: 300,
                                        fontSize: {xs: 16, md: 20}
                                        }}>
                                        Private dining isn’t just for special occasions—it’s for anyone who loves great food, 
                                        personalized service, and a unique culinary experience. Whether you’re gathering with 
                                        friends, celebrating a milestone, or simply indulging in an elevated meal, our thoughtfully 
                                        curated menus bring restaurant-quality dining to an intimate setting.
                                </Typography>

                            </Box>

                            {/* Image list */}
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center', 
                                justifyContent: 'center',
                                width: '100%',
                                // background: 'red'
                                }}>

                                <Grid2 container rowSpacing={{xs: 2, md: 1}} columnSpacing={{xs: 1, lg: 2}} 
                                    sx={{ alignItems: 'center', 
                                    justifyContent: 'center',
                                    width: '95%'}}>

                                    <Grid2 size={{xs: 12, sm: 6}}>
                                        <img 
                                            src={img1} 
                                            width={'100%'} 
                                            style={{borderRadius: '0.5rem'}}
                                            alt="View from top"/>
                                    </Grid2>

                                    <Grid2 size={{xs: 12, sm: 6}}>
                                        <img 
                                            src={img2} 
                                            width={'100%'} 
                                            style={{borderRadius: '0.5rem'}}
                                            alt="View from top"/>
                                    {/* <Item>2</Item> */}
                                    </Grid2>

                                    <Grid2 size={{xs: 12, sm: 6}}>
                                        <img 
                                            src={img3} 
                                            width={'100%'} 
                                            style={{borderRadius: '0.5rem'}}
                                            alt="View from top"/>
                                    </Grid2>

                                    <Grid2 size={{xs: 12, sm: 6}}>
                                        <img 
                                            src={img4} 
                                            width={'100%'} 
                                            style={{borderRadius: '0.5rem'}}
                                            alt="View from top"/>
                                    </Grid2>

                                </Grid2>

                            </Box>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center', 
                                justifyContent: 'center',
                                width: '95%',
                                pt: '1rem',
                                pb: '1rem',
                                // background: 'red'
                                }}>

                                <Typography
                                    textAlign={'justify'} 
                                    sx={{
                                        marginBottom: '14px',
                                        fontFamily: 'Neuton',
                                        fontWeight: 300,
                                        fontSize: {xs: 16, md: 20}
                                        }}>
                                        Choose from our 5-course menu <Box display={'inline'} sx={{fontWeight: 600}}>($75)</Box> or 
                                        8-course menu <Box display={'inline'} sx={{fontWeight: 600}}>($125)</Box>, each crafted with 
                                        fresh, high-quality ingredients and designed to take you on a flavorful journey. No crowds, 
                                        no rush—just exceptional food, great company, and an experience tailored just for you. <br />
                                        <br />Let’s make your next meal unforgettable. Book your private dining experience today!
                                </Typography>

                            </Box>

                            {/* Reservation */}
                            <Box sx={{
                                display: 'flex', 
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center', 
                                width: '95%', 
                                // mt: '1rem',
                                mb: '2rem',
                                background: '#F8F6F1',
                                }}>
                                <Box sx={{
                                    display: 'flex', 
                                    flexDirection: {xs: 'column', md: 'row'},
                                    alignItems: 'center',
                                    justifyContent: 'center', 
                                    width: '95%',
                                    m: '1rem',
                                    }}>

                                    <Box sx={{
                                        display: 'flex', 
                                        width: {xs: '100%', md: '120%'}, 
                                        alignItems: 'center', 
                                        // background: 'orange',
                                        justifyContent: 'center',}}>
                                        {value === null ? 
                                            <Typography sx={{
                                                fontFamily: 'Neuton',
                                                fontWeight: 500,
                                                fontSize: {xs: 20, md: 20},
                                                mt: {xs: '1rem', md: 0},
                                                mb: {xs: '1rem', md: 0},
                                                }}>
                                                Choose a date for private dining
                                            </Typography> 
                                            : 
                                            <Typography sx={{
                                                // marginBottom: '14px',
                                                fontFamily: 'Neuton',
                                                fontWeight: 500,
                                                fontSize: {xs: 16, md: 20}
                                                }}>
                                                {value.format('dddd DD/MM/YYYY')}
                                            </Typography>
                                            }
                                    </Box>

                                    <Box sx={{
                                        display: 'flex',
                                        width: {xs: '100%', md: '80%'},  
                                        alignItems: 'center',
                                        justifyContent: {xs: 'center', md: 'left'}, 
                                        mb: {xs: '1rem', md: 0},
                                        // background: 'green'
                                        }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker 
                                                label="Pickup Date" 
                                                value={value} 
                                                minDate={tot.add(cutoff, 'day')}
                                                maxDate={tot.add(3, 'month')}
                                                slotProps={{
                                                    field: {
                                                        color: theme.palette.primary.dark
                                                    }
                                                }}
                                                onChange={(newValue) => {
                                                    // console.log(newValue)
                                                    setValue(newValue)
                                                    }} />
                                        </LocalizationProvider>
                                    </Box>

                                </Box>
                            </Box>

                            <Box sx={{
                                display: (value) != null ? 'flex' : 'none',
                                flexDirection: 'column',
                                alignItems: 'center', 
                                justifyContent: 'center',
                                width: '95%',
                                pt: '1rem',
                                pb: '1rem',
                                }}>
                                <Emailform2 date={value}/>
                            </Box>

                        </Box>

                    }

                </Container>
            </Layout>
        </div>
    )
}