import React, { useContext, useEffect, useState }  from "react";
import { CartContext } from "../context/cart-context";
import { 
    Card, 
    CardMedia, 
    Typography, 
    Grid, 
    CardActionArea, 
    CardHeader, 
    Box } from "@mui/material";
import { alpha } from '@mui/material/styles';
import { grey } from "@mui/material/colors";
import { Link } from 'react-router-dom';
import ColorButton from "./coloredButton";
import { styled } from '@mui/material/styles';

const StyledCardActionArea = styled(CardActionArea)(({theme}) => `
    .MuiCardActionArea-focusHighlight {
        background: transparent;
    }
`);

function ProductGallery({ payload }) {

    const { addProduct } = useContext(CartContext)
    const { attributes, default_price, id, images, metadata, name } = payload
    const [root, setRoot] = useState('')

    useEffect( () => {

        // console.log(attributes)

        if (attributes.length === 1 & attributes[0] === "Specialty") {
            // console.log("HERE")
            setRoot('/product/sp')
        } else {
            setRoot('/product/oo')
        }

        return( () => {})

    }, [])

    return(
        <Grid item xs={10} sm={5} md={6} key={id} >
            <Card 
                variant='outlined' 
                sx={{
                    borderWidth: 0,
                    background: alpha('#F8F6F1', 0.6),
                    '&:hover': {
                        zIndex: 1,
                        borderWidth: 1,
                        background: alpha('#F8F6F1', 0.75),
                    },
                    }}>
                <StyledCardActionArea 
                    sx={{
                        fontFamily: 'inherit',
                        // ":focus": {background: alpha('#F8F6F1', 0.75)},
                        // '&:hover': {
                        //     background: alpha('#F8F6F1', 0.75),
                        // },
                    }} 
                    component={Link} 
                    to={`${root}/${metadata.webid}`}>
                    <CardMedia
                        component={'img'}
                        image={images[0]}
                        title={name}
                        // onClick={() => {navigate(`/product/${name}`)}}
                        />
                    <CardHeader 
                        disableTypography 
                        title={
                            <>
                            <Typography 
                                textAlign={'center'} 
                                sx={{
                                    fontSize: {xs: 24, sm: 20},
                                    mb: '1rem',
                                    // lineHeight: 2,  
                                    fontFamily:'Neuton',
                                    color: grey[800], 
                                    fontWeight: 400}}>
                                    {name}
                            </Typography>

                            <Typography 
                                textAlign={'center'} 
                                sx={{
                                    fontSize: {xs: 24, sm: 20},
                                    mb: '1rem',
                                    // lineHeight: 2,  
                                    fontFamily:'Neuton',
                                    color: grey[800], 
                                    fontWeight: 300}}>
                                    {metadata.size}
                            </Typography>
                            </>
                            }
                        subheader={
                            <Typography 
                            textAlign={'center'} 
                            sx={{fontSize: {xs: 24, sm: 20}, 
                                fontFamily:'Neuton',
                                color: grey[700], 
                                fontWeight: 400}}>
                                $ {(default_price.unit_amount)/100}
                            </Typography>
                        }/>
                </StyledCardActionArea>

                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    }}>

                    <ColorButton
                        variant='contained' 
                        disableElevation
                        onClick={() => {
                            addProduct(payload)
                        }} 
                        sx={{
                            width: {xs: '80%', md: '50%'},
                            mt: '1rem',
                            mb: '1rem'
                        }}>
                        ADD TO CART
                    </ColorButton>
                    
                </Box>
                
            </Card>
        </Grid>

    )
}

export default ProductGallery